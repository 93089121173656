html {
  font-size: 16px;
}
#app-root {
  animation: none;
  animation-delay: 0;
  animation-direction: normal;
  animation-duration: 0;
  animation-fill-mode: none;
  animation-iteration-count: 1;
  animation-name: none;
  animation-play-state: running;
  animation-timing-function: ease;
  backface-visibility: visible;
  background: 0;
  background-attachment: scroll;
  background-clip: border-box;
  background-color: transparent;
  background-image: none;
  background-origin: padding-box;
  background-position: 0 0;
  background-position-x: 0;
  background-position-y: 0;
  background-repeat: repeat;
  background-size: auto auto;
  border: 0;
  border-style: none;
  border-width: medium;
  border-color: inherit;
  border-bottom: 0;
  border-bottom-color: inherit;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-style: none;
  border-bottom-width: medium;
  border-collapse: separate;
  border-image: none;
  border-left: 0;
  border-left-color: inherit;
  border-left-style: none;
  border-left-width: medium;
  border-radius: 0;
  border-right: 0;
  border-right-color: inherit;
  border-right-style: none;
  border-right-width: medium;
  border-spacing: 0;
  border-top: 0;
  border-top-color: inherit;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top-style: none;
  border-top-width: medium;
  bottom: auto;
  box-shadow: none;
  box-sizing: content-box;
  caption-side: top;
  clear: none;
  clip: auto;
  color: inherit;
  columns: auto;
  column-count: auto;
  column-fill: balance;
  column-gap: normal;
  column-rule: medium none currentColor;
  column-rule-color: currentColor;
  column-rule-style: none;
  column-rule-width: none;
  column-span: 1;
  column-width: auto;
  content: normal;
  counter-increment: none;
  counter-reset: none;
  cursor: auto;
  direction: ltr;
  display: inline;
  empty-cells: show;
  float: none;
  font: normal;
  font-family: inherit;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  height: auto;
  hyphens: none;
  left: auto;
  letter-spacing: normal;
  line-height: normal;
  list-style: none;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: disc;
  margin: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  max-height: none;
  max-width: none;
  min-height: 0;
  min-width: 0;
  opacity: 1;
  orphans: 0;
  outline: 0;
  outline-color: invert;
  outline-style: none;
  outline-width: medium;
  overflow: visible;
  overflow-x: visible;
  overflow-y: visible;
  padding: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  page-break-after: auto;
  page-break-before: auto;
  page-break-inside: auto;
  perspective: none;
  perspective-origin: 50% 50%;
  position: static;
  /* May need to alter quotes for different locales (e.g fr) */
  quotes: '\201C' '\201D' '\2018' '\2019';
  right: auto;
  tab-size: 8;
  table-layout: auto;
  text-align: inherit;
  text-align-last: auto;
  text-decoration: none;
  text-decoration-color: inherit;
  text-decoration-line: none;
  text-decoration-style: solid;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  top: auto;
  transform: none;
  transform-style: flat;
  transition: none;
  transition-delay: 0s;
  transition-duration: 0s;
  transition-property: none;
  transition-timing-function: ease;
  unicode-bidi: normal;
  vertical-align: baseline;
  visibility: visible;
  white-space: normal;
  widows: 0;
  width: auto;
  word-spacing: normal;
  z-index: auto;
  /* basic modern patch */
  all: initial;
  all: unset;
  appearance: none;
}
/* --- on/off --- */
#otr > .head {
  display: block;
}
.ReactModalPortal {
  position: absolute;
}
/* =======================================
	COLORS
======================================= */
/* --- txt SOFA --- */
#otr button,
#otr .price,
#otr .km,
#otr > .head,
#otr a {
  color: #e30613;
}
/* --- BG SOFA --- */
#otr .btn,
#otr .switch > input:checked + label,
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside),
#otr #progress {
  background-color: #e30613;
  color: #ffffff;
}
/* --- BG White --- */
#otr,
#otr > .main,
#otr .switch > input + label,
#otr .overlay > div {
  background-color: #ffffff;
  color: #000000;
}
/* --- BG Gray (light) --- */
.DayPicker-Weekdays,
.DayPicker-Caption,
#otr .probar,
#otr .gray {
  background-color: rgba(0, 0, 0, 0.06);
  color: #000000;
}
/* --- BG Gray (dark) --- */
#otr button[disabled].btn,
#otr button[disabled].btn:hover {
  background-color: rgba(0, 0, 0, 0.3);
  color: #ffffff;
}
/* --- BG Black --- */
#otr .overlay > a {
  background-color: #000000;
  color: #ffffff;
}
/* =======================================
	TYPO
======================================= */
#otr {
  font-size: 1rem;
}
/*---- Fonts --- */
#otr,
#otr button {
  font-weight: 100;
  font-family: 'sofi', Arial, Helvetica, sans-serif;
}
#otr strong,
#otr input,
#otr select,
#otr textarea,
#otr button.btn,
#otr .head,
#otr .sub,
#otr .price,
#otr .km,
.DayPicker-Caption,
#otr .overlay p.name {
  font-weight: 200;
  font-family: 'sofibo', Arial, Helvetica, sans-serif;
}
@font-face {
  font-family: 'sofi';
  src: url('../../node_modules/@itt/otr/dist/fonts/volvo/VolvoSans-Light.woff') format('woff2');
}
@font-face {
  font-family: 'sofibo';
  src: url('../../node_modules/@itt/otr/dist/fonts/volvo/VolvoSans-Regular.woff') format('woff2');
}
#otr {
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-size: 1rem;
  position: relative;
  width: 96%;
  max-width: 1024px;
  margin: 0;
  padding: 0 0 1.2em 1.2em;
  overflow: hidden;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-content: flex-start;
  align-items: flex-start;
}
#otr a {
  cursor: pointer;
  text-decoration: none;
}
#otr a:hover {
  text-decoration: underline;
}
#otr p + p {
  margin-top: 0.6em;
}
#otr p + div {
  margin-top: 1.2em;
}
#otr > div {
  margin: 1.2em 1.2em 0 0;
}
#otr .head {
  order: 1;
  flex: 1 1 100%;
  margin-bottom: 0.6em;
  margin-top: 0;
  font-size: 1.5em;
}
/* optional */
#otr .status {
  order: 2;
  flex: 1 1 200px;
  padding: 0.9em;
}
#otr .main {
  order: 3;
  flex: 1 1 600px;
  overflow: hidden;
}
/* --- Main --- */
#otr .sub {
  margin-bottom: 1.2em;
  text-transform: uppercase;
  letter-spacing: 0.12em;
}
#otr .status .sub {
  flex: 1 1 100%;
}
#otr #slide {
  width: 700%;
  /* <-- !!! */
  margin-left: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-content: flex-start;
  align-items: flex-start;
  transition: margin-left ease-out 300ms;
}
#otr .step {
  flex: 1 1 14.28%;
  /* <-- !!! */
  margin-bottom: 4em;
}
/* --- toggleslidemodus start ---*/
#otr.slidemodus #slide {
  width: 100%;
  /* <-- !!! */
  margin-left: 0 !important;
}
#otr.slidemodus .step {
  flex: 0 0 100%;
}
/* --- toggleslidemodus ende ---*/
#otr .probar {
  width: calc(100.00% - 2px);
  /* <-- !!! */
  height: 0.15em;
  margin-bottom: 1.2em;
  overflow: hidden;
  border-width: 0;
}
#otr .probar #progress {
  width: 1%;
  /* <-- !!! */
  height: 100%;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 0.24) 90%);
  transition: width ease-out 300ms;
}
#otr .group {
  width: 100%;
  padding: 0.6em 0;
  border: 0px solid rgba(0, 0, 0, 0.15);
  border-top-width: 1px;
}
#otr .group .flap {
  width: 100%;
  height: auto;
  overflow: hidden;
  transition: max-height ease-out 600ms;
}
/* --- Line --- */
#otr .group .line {
  position: relative;
  width: 100%;
  min-height: 3.2em;
  padding: 0.6em 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: stretch;
}
#otr .group .flap .line {
  border-top-width: 1px;
}
#otr .group .line .txt {
  width: 78%;
  margin-right: 2%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}
#otr .group .line .txt > .img {
  margin-right: 2%;
  align-self: flex-start;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}
#otr .group .line .txt > .img.local {
  flex: 1 1 28%;
}
#otr .group .line .txt > .img.icon {
  flex: 1 1 10%;
  max-width: 4em;
}
#otr .group .line .txt > .img > img {
  width: 100%;
  height: auto;
}
#otr .group .line .txt > .img.icon > img {
  box-shadow: inset 0 0 0 2px white;
}
#otr .group .line .txt > p {
  flex: 1 1 70%;
  margin-top: 0.3em;
  line-height: 1.2;
}
#otr .group .line .txt > p > span {
  display: block;
  margin-top: 0.15em;
  font-size: 0.84em;
}
#otr .group .line .txt > p > div > .openHours {
  margin-top: 0.7em;
}
#otr .group .line .txt > p > .price {
  display: inline-block;
  float: right;
  margin-left: 0.6em;
}
#otr .group .line .right {
  position: relative;
  width: 15%;
  margin-left: 2%;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  /* align-content: center;*/
  align-items: center;
}
#otr .group .line .right .info {
  position: absolute;
  opacity: 0.42;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
  background: url(../../node_modules/@itt/otr/dist/img/pict/ic_info_outline_black_24px.svg) no-repeat center;
  background-size: contain;
}
#otr .group .line .right .info:hover {
  opacity: 1;
}
#otr .group .line .right .info + .switch {
  align-self: flex-end;
  /* <-- !!!*/
}
#otr .group .line .right > .for-flap {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 24px;
}
#otr .group .line .right > .for-flap.on-flap {
  background-image: url(../../node_modules/@itt/otr/dist/img/pict/ic_add_black_24px.svg);
}
#otr .group .line .right > .for-flap.off-flap {
  background-image: url(../../node_modules/@itt/otr/dist/img/pict/ic_close_black_24px.svg);
  display: none;
}
#otr .group .line .label {
  flex: 0 0 150px;
  height: calc(2.40em + 2px);
  font-size: 0.84em;
  line-height: 1;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}
#otr .group .line .label + .small {
  flex: 1 1 58%;
}
#otr .group .line .label + .large {
  flex: 1 1 70%;
}
#otr .group .line .label ~ .right {
  align-content: center;
  align-items: center;
}
#otr .group .line.mini {
  min-height: inherit;
  padding: 0 0 1.2em;
  border-top-width: 0;
}
#otr .group .line .blank {
  flex: 1 1 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}
#otr .group .line .blank .label {
  height: calc(1.80em + 2px);
  color: black;
}
#otr .group .line .blank + .blank {
  margin-top: 0.9rem;
}
#otr .input-group + .input-group {
  margin-top: 0.9rem;
}
/* --- Summary --- */
#otr .summary {
  padding: 0.6em;
  margin-bottom: 1.2em;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
#otr .summary > span {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: stretch;
}
#otr .summary > span > div {
  flex: 1 1 280px;
  margin: 0.6em;
}
#otr .summary > span {
  width: 100%;
}
#otr .summary > span > div > table {
  width: 100%;
}
/* <-- !!!*/
/* --- Overlay --- */
#otr .myOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.66);
  z-index: 999999;
}
.myModal {
  border: none;
}
#otr .overlay > div {
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
  z-index: 2;
  width: calc(88.00% - 1.20rem - 2px);
  margin-top: 10vh;
  left: 6%;
  padding: 0.6em;
  border: 1px solid rgba(0, 0, 0, 0.15);
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: flex-start;
  align-items: flex-start;
}
#otr .overlay > div > div:nth-child(1) {
  flex: 1 1 100%;
}
#otr .overlay > div > div:nth-child(2) {
  flex: 1 1 150px;
}
#otr .overlay > div > div:nth-child(3) {
  flex: 1 1 600px;
}
#otr .overlay > div > div:nth-child(4) {
  flex: 1 1 100%;
}
#otr .overlay > div > div {
  margin: 0.6rem;
}
#otr .overlay > div > div:nth-child(1) {
  padding-bottom: 0.6em;
  border: 0px solid rgba(0, 0, 0, 0.15);
  border-bottom-width: 1px;
}
#otr .overlay > div > div:nth-child(2) > img {
  width: 100%;
  height: auto;
}
#otr .overlay > div > div:nth-child(2) > div {
  width: 100%;
  margin-top: 1.2rem;
}
#otr .overlay > div > div:nth-child(2) > div > img {
  width: auto;
  height: 3rem;
  margin-right: 0.6rem;
  display: inline-block;
}
#otr .overlay > div > div:nth-child(3) {
  flex: 1 1 50%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-content: flex-start;
  align-items: flex-start;
}
#otr .overlay > div > div:nth-child(3) p + p {
  flex: 1 1 100%;
}
#otr .overlay > div > div:nth-child(3) div + div {
  margin-top: 1.2rem;
}
#otr .overlay > div > div:nth-child(3) p.name {
  font-size: 1.2rem;
  line-height: 1.2;
}
#otr .overlay > div > div:nth-child(3) span {
  display: block;
  width: 100%;
  text-align: left;
}
#otr .overlay > div > div:nth-child(3) .price {
  font-size: 1.5rem;
}
#otr .overlay > div > div:nth-child(4) {
  padding-top: 0.6em;
}
/* --- Elements --- */
#otr table {
  border-collapse: collapse;
  border-spacing: 0;
  /* width: 100.0%; */
  flex: 0 0 30%;
  font-size: 0.9em;
}
#otr table td {
  padding: 0.3em 0.2em;
  border: 0px solid rgba(0, 0, 0, 0.15);
  border-bottom-width: 1px;
}
#otr table tr td.org {
  opacity: 0.6;
  padding-right: 1.2em;
}
#otr table tr td.openHoursLine {
  padding: 0.2em 0.5em 0.2em 0;
  border: none;
}
#otr table tr td.right {
  text-align: right;
}
#otr table tr td.nowrap {
  white-space: nowrap;
}
#otr table + table tr:last-child td {
  border: 0;
}
#otr .link {
  align-self: center;
  display: block;
  margin: 0 1em 0 0;
  line-height: 1.6;
  cursor: pointer;
}
#otr .link span {
  display: inline-block;
  line-height: 1.2;
}
#otr .link.ico {
  padding-left: 2.4em;
  background-repeat: no-repeat;
  background-position: left center;
}
#otr .link.ico.cal {
  background-image: url(../../node_modules/@itt/otr/dist/img/pict/ic_event_black_24px.svg);
}
#otr .link.ico.mob {
  background-image: url(../../node_modules/@itt/otr/dist/img/pict/ic_local_taxi_black_24px.svg);
}
#otr .link.ico.memory {
  background-image: url(../../node_modules/@itt/otr/dist/img/pict/ic_alarm_on_black_24px.svg);
}
#otr .link.ico.close {
  background-image: url(../../node_modules/@itt/otr/dist/img/pict/ic_close_black_24px.svg);
}
#otr .link.ico.undo {
  background-image: url(../../node_modules/@itt/otr/dist/img/pict/ic_undo_black_24px.svg);
}
#otr .link.ico.print {
  background-image: url(../../node_modules/@itt/otr/dist/img/pict/ic_print_black_24px.svg);
}
#otr .link:hover {
  text-decoration: underline;
}
/* --- Groups --- */
#otr .openGroup {
  width: calc(2.40em - 2px);
  height: calc(2.40em - 2px);
  background: #fff url(../../node_modules/@itt/otr/dist/img/pict/ic_keyboard_arrow_down_black_24px.svg) no-repeat top 50% right 0.6em;
  background-size: 24px;
}
#otr .closeGroup {
  width: calc(2.40em - 2px);
  height: calc(2.40em - 2px);
  background: #fff url(../../node_modules/@itt/otr/dist/img/pict/ic_keyboard_arrow_up_black_24px.svg) no-repeat top 50% right 0.6em;
  background-size: 24px;
}
#otr .hideGroup {
  display: none;
}
#otr .showGroup {
  display: block;
}
#otr .group .group {
  width: 90%;
  float: right;
}
#otr .ServiceGroup {
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-top: 1px solid #999;
}
#otr .ServiceGroup ~ .ServiceGroup {
  border-bottom: 1px solid #999;
  border-left: 0;
  border-right: 0;
  border-top: 1px solid #999;
}
#otr .clear {
  clear: both;
}
/* --- Switch --- */
/* --- Control --- */
#otr .control {
  width: 100%;
  padding-top: 2.4rem;
  border: 0px solid rgba(0, 0, 0, 0.15);
  border-top-width: 1px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}
#otr button {
  margin: 0;
  padding: 0;
  font-size: 1em;
  cursor: pointer;
  background-color: transparent;
}
#otr button:hover {
  color: #000000;
}
#otr button {
  border: none;
}
#otr button.btn {
  font-size: 0.9em;
  padding: 0.6em 2.4em 0.6em 1.2em;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  transition: background-color ease-out 200ms;
  background-image: url(../../node_modules/@itt/otr/dist/img/pict/ic_arrow_forward_white_24px.svg);
  background-repeat: no-repeat;
  background-position: center right 0.6em;
  background-size: 1.5em;
}
#otr button:hover.btn {
  background-color: #000000;
  color: #ffffff;
  transition: background-color ease-out 200ms;
}
/*	=================================
		SCREENS
	================================= */
@media screen and (min-width: 0) and (max-width: 767px) {
  #otr .group .line > .txt > .img.local {
    display: none;
  }
  #otr .status {
    display: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) and screen and (orientation: portrait) {
  #otr .status {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  #otr.appointment .group.garage .line .txt {
    width: 100%;
  }
  #otr.appointment .group.garage .line .right {
    width: 100%;
    margin: 0;
  }
  #otr.appointment .group.garage .line .right .btn {
    width: calc(100% - 35px);
    float: left;
    margin-right: 35px;
  }
}
/*	=================================
		ENDE
	================================= */
/* DayPicker styles */
.DayPicker {
  display: block;
  width: 100%;
  /* gw */
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.DayPicker-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 0;
  /* gw */
}
.DayPicker-Month {
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0;
  width: calc(100.0% - 0.00rem);
  /* gw */
}
.DayPicker-Months {
  width: 100%;
}
.DayPicker-NavBar {
  position: absolute;
  left: 0;
  right: 0;
  padding: 0 0.5rem;
  top: 0.6em;
  /* gw */
}
.DayPicker-NavButton {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
}
.DayPicker-NavButton--prev {
  left: 0.3em;
  /* gw */
  background-image: url("../../node_modules/@itt/otr/dist/img/pict/ic_keyboard_arrow_left_black_24px.svg");
  /* gw */
}
.DayPicker-NavButton--next {
  right: 0.3em;
  /* gw */
  background-image: url("../../node_modules/@itt/otr/dist/img/pict/ic_keyboard_arrow_right_black_24px.svg");
  /* gw */
}
.DayPicker-NavButton--interactionDisabled {
  display: none;
}
.DayPicker-Caption {
  display: table-caption;
  height: 1.5rem;
  /* gw */
  text-align: center;
  vertical-align: middle;
  /* gw */
  font-size: 1rem;
  /* gw */
  padding-top: 0.6em;
  /* gw */
}
.DayPicker-Weekdays {
  display: table-header-group;
}
.DayPicker-WeekdaysRow {
  display: table-row;
}
.DayPicker-Weekday {
  display: table-cell;
  padding: 0.5rem;
  font-size: 0.9em;
  /* gw */
  text-align: center;
  color: inherit;
  /* gw */
}
.DayPicker-Body {
  display: table-row-group;
}
.DayPicker-Week {
  display: table-row;
  border: 0px solid rgba(0, 0, 0, 0.15);
  border-top-width: 1px !important;
  /* gw */
}
.DayPicker-Day {
  display: table-cell;
  width: 14.28%;
  padding: 0.5rem;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
}
/* gw */
.DayPicker-Day:hover {
  font-weight: bold;
  color: #fff;
  background-color: #000;
}
.DayPicker-Day,
.DayPicker-Day:hover {
  transition: all ease-out 180ms;
}
.DayPicker-WeekNumber {
  display: table-cell;
  padding: 0.5rem;
  text-align: right;
  vertical-align: middle;
  min-width: 1rem;
  font-size: 0.75em;
  cursor: pointer;
  color: #8b9898;
}
.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}
.DayPicker-Footer {
  display: table-caption;
  caption-side: bottom;
  padding-top: 0.5rem;
}
.DayPicker-TodayButton {
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
  color: #e30613;
  font-size: 0.875em;
}
/* Default modifiers */
.DayPicker-Day--today {
  color: #d0021b;
  font-weight: 500;
}
.DayPicker-Day--disabled {
  color: inherit;
  cursor: default;
  background-color: inherit;
  /* gw **/
  opacity: 0.3;
  /* gw */
  /* gw */
}
/* gw */
.DayPicker-Day--disabled:hover {
  font-weight: normal;
  color: #000;
  background-color: #fff;
}
.DayPicker-Day--outside {
  cursor: default;
  color: inherit;
  /* gw **/
}
/* Example modifiers */
/*
.DayPicker-Day--sunday {
  background-color: ;
}
*/
.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #dce0e0;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  color: #FFF;
  background-color: #e30613;
}
/* DayPickerInput */
.DayPickerInput {
  display: inline-block;
}
.DayPickerInput-OverlayWrapper {
  position: relative;
}
.DayPickerInput-Overlay {
  left: 0;
  position: absolute;
  background: white;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}
.example-enter {
  opacity: 0.01;
}
.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.example-leave {
  opacity: 1;
}
.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
.example-appear {
  opacity: 0.01;
}
.example-appear.example-appear-active {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}
.line > div {
  width: 100%;
}
#otr select,
#otr input,
#otr textarea {
  margin: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.input + .input {
  margin-top: -1px;
}
::-moz-placeholder {
  font-family: sofi-200, Arial, Helvetica, sans-serif;
  font-size: 0.9em;
}
::-webkit-input-placeholder {
  font-family: sofi-200, Arial, Helvetica, sans-serif;
  font-size: 0.9em;
}
:-ms-input-placeholder {
  font-family: sofi-200, Arial, Helvetica, sans-serif;
  font-size: 0.9em;
}
#otr input,
#otr select,
#otr textarea {
  font-size: 1em;
}
#otr input[type=checkbox],
#otr input[type=radio] {
  display: none;
}
placeholder {
  font-size: 0.84em;
  font-style: italic;
}
#otr select,
#otr input[type=tel],
#otr input[type=datetime],
#otr input[type=text],
#otr input[type=number],
#otr input[type=email] {
  width: calc(100.0% - 1.20em - 2px);
  height: 2.4em;
  padding: 0 0.6em;
  border-radius: 0;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
#otr select.error,
#otr input[type=tel].error,
#otr input[type=datetime].error,
#otr input[type=text].error,
#otr input[type=number].error,
#otr input[type=email].error {
  border: 1px solid red;
}
#otr select {
  width: 100%;
  height: calc(2.40em - 2px);
  background: #fff url(../../node_modules/@itt/otr/dist/img/pict/ic_keyboard_arrow_down_black_24px.svg) no-repeat top 50% right 0.6em;
  background-size: 24px;
}
#otr select:disabled {
  opacity: 0.6;
  background: #fff;
}
/*#otr select.semi {
  width: 6.00em;
  display: inline-block;
  margin: 0;
}

.input + .input input,
#otr select + select {
  margin-top: -1px;
}

#otr select.semi + select.semi {
  margin-left: -6px;
}*/
#otr select.semi {
  width: 6em;
  display: inline-block;
}
#otr select.semi + select.semi {
  margin-left: -1px;
}
#otr div.large + div.label,
#otr div.small + .right + div.label {
  margin-top: 0;
}
#otr div.large + div.label ~ div.large,
#otr div.small + .right + div.label ~ div.small,
#otr div.small + .right + div.label ~ div.large {
  margin-top: 0.6em;
}
#otr input[type=datetime]:disabled {
  width: calc(100.0% - 2px);
  padding: 0;
  height: auto;
  background: #fff;
  color: #000;
  border: 0;
}
#otr textarea {
  resize: vertical;
  width: 100%;
  min-height: 6em;
  border: 0px solid rgba(0, 0, 0, 0.15);
  border-width: 1px 1px 0px 1px;
  padding: 0.6em 3em 0.6em 0.6em;
  background: #fff url(../../node_modules/@itt/otr/dist/img/pict/ic_mail_outline_black_24px.svg) no-repeat top 0.6em right 0.6em;
  background-size: 24px;
}
#otr .error input {
  color: red;
}
#otr .switch {
  display: inline-block;
  position: relative;
  height: 26px;
  width: 44px;
  margin-right: 6px;
}
#otr .switch + span {
  display: inline-block;
  position: relative;
  top: -9px;
}
#otr .switch label {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 24px;
  width: 42px;
  border-radius: 50px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: background-color ease-out 200ms 100ms;
}
#otr .switch label span {
  background-color: #ffffff;
  position: absolute;
  top: 1px;
  right: 19px;
  height: 22px;
  width: 22px;
  border-radius: 100%;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.6);
  transition: right ease-out 200ms;
}
#otr .switch > input:checked + label span {
  right: 1px;
}
.summary.disabled .link {
  display: none !important;
}
#otr .switch > input + label span {
  top: 0px;
}
.off-canvas-wrap #main .summary.disabled .link {
  display: none !important;
}
.off-canvas-wrap #main .inner .inner {
  width: 100% !important;
}
.off-canvas-wrap #main #nav-rootline > ul > li > span {
  z-index: unset !important;
}
.off-canvas-wrap #main .container-rootline {
  z-index: unset !important;
}
.off-canvas-wrap #main #root form {
  background: none !important;
  box-shadow: none !important;
}
.off-canvas-wrap #main #root #otr button,
.off-canvas-wrap #main #root #otr .price,
.off-canvas-wrap #main #root #otr .km,
.off-canvas-wrap #main #root #otr > .head,
.off-canvas-wrap #main #root #otr a {
  color: #183884;
}
.off-canvas-wrap #main #root #otr .btn,
.off-canvas-wrap #main #root #otr .switch > input:checked + label,
.off-canvas-wrap #main #root .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside),
.off-canvas-wrap #main #root #otr #progress {
  background-color: #183884;
  color: #ffffff;
}
.off-canvas-wrap #main #root .DayPicker-TodayButton {
  color: #183884;
}
.off-canvas-wrap #main #root .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  color: #FFF;
  background-color: #183884;
}
@font-face {
  font-family: 'sofi-200';
  src: url('fonts/opensans-200-regular.woff') format('woff');
}
@font-face {
  font-family: 'sofi-300';
  src: url('fonts/opensans-300-semi-bold.woff') format('woff');
}
